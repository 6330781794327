@import "@/src/styles/common.scss";
.size-guide {
    display: inline;
    @include use-icon-small(null, $main);

    .MuiBackdrop-root {
        background-color: #ffffff;
    }
    &-title {
        color: $text;
        font-size: 0.32rem;
        margin-top: 0.35rem;
        font-family: var(--font-montserrat-bold);
    }
    &-modal {
        overflow: auto;
        background-color: #ffffff;

        &-content {
            background-color: #ffffff;
            color: #888888;
            font-size: 15px;
            text-align: center;
            flex-direction: column;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .position-fixed-icon {
            position: fixed;
        }
    }
}
