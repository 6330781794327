@import "@/src/styles/common.scss";
@import "./variable.module.scss";
.add-right.end-adrnment {
    position: absolute;
    right: 0.3rem;
    @include use-icon-small();
    &.opened-icon {
        transform: rotate(180deg);
    }
}
.drawer-container {
    .paper-radius {
        border-radius: 0.2rem 0.2rem 0 0;
    }
    .header-children-for-picker {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .header-button.confirm-button,
        .header-button.cancel-button {
            width: auto;
            margin: 0;
            padding: 0;
            font-size: 0.32rem;
        }
    }
    .draw-header-container {
        height: auto;
        padding: 0.4rem 0.3rem 0;
    }
    .picker-children-container {
        padding: 0 !important;
        .menu-list-container {
            padding: 0;
            .menu-item {
                font-size: 0.32rem;
                display: flex;
                justify-content: center;
                color: $placeholderColor;
                font-family: var(--font-montserrat);
                opacity: 0.3;
                &.active-item {
                    color: $text;
                    opacity: 1;
                }
            }
            // .menu-item + .menu-item {
            //     margin-top: 0.4rem;
            // }
        }
    }
}
