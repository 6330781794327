@import "@/src/styles/common.scss";
.radio-container {
    display: flex;
    align-items: flex-start;
    align-items: center;
}

.radio-box {
    width: 0.44rem;
    height: 0.44rem;
    background: rgba(255, 255, 255, 1);
    border: 0.02rem solid $text_3;
    margin-right: 0.21rem;
    border-radius: 0.08rem;
}

.radio-container p {
    @include use-font-normal();
}

.radio-img {
    height: auto;
    margin-right: 0.3rem;
}

.radio-box-checked {
    width: 0.44rem;
    height: 0.44rem;
    margin-right: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.08rem;
    & {
        border: 0.02rem solid $main;
        flex-shrink: 0;
        background: $main;
    }
    &-2 {
        background: #fff;
        border: 0.02rem solid #cccccc;
    }
}

.check-img {
    width: 0.24rem;
    height: 0.24rem;
}

.radio-box-disable {
    background: #dfdfdf;
    opacity: 0.5;
}

.radio-title {
    flex: auto;
    text-align: left;
}
