@import "@/src/styles/common.scss";
.firmoo-register-container {
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0.98rem 0.4rem 0;

    .firmoo-register-tips {
        @include use-font-normal($text);
        text-align: left;
        margin-top: 0;
    }
    .firmoo-register-error {
        padding: 0.1rem 0.2rem;
        background: $warning;
        @include use-font-small(#fff);
        align-self: flex-start;
        margin-top: 0.4rem;
        width: fit-content;
    }
    .firmoo-register-name {
        margin-top: 0.4rem;
        text-align: left;
        .register-name-picker {
            display: flex;
            justify-content: space-between;
            margin-top: 0.2rem;
            align-items: flex-end;
            .register-name-input {
                margin-left: 0.2rem;
                margin-top: 0;
            }
            .picker-icon {
                font-size: 0.44rem;
            }
            .picker-input {
                font-size: 0.28rem;
            }
            .picker-input-classname {
                font-size: 0.28rem;
            }
        }
    }
    .email-input {
        margin-top: 0.4rem;
    }
    .show-password-btn.firmoo-register-password.make-btn-more-higher {
        width: auto;
        height: 0.98rem;
    }
    .create-btn.make-btn-more-higher.fm-btn {
        border-radius: 0.08rem;
        margin-top: 0.4rem;
        height: 0.98rem;
    }
    .firmoo-login-or {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        height: 0.28rem;
        margin: 0.4rem 0;
        .firmoo-login-or-line {
            width: 45%;
            height: 0.02rem;
            background: rgba(228, 228, 228, 1);
        }
    }
    .checked-receive-container {
        margin-top: 0.2rem;
        .label-container {
            @include use-font-small($placeholderColor, !important);
        }
    }
    .registered-email-dangerous {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 0.2rem;
    }
}

.picker-container {
    .picker-children {
        .item-picker {
            font-size: 0.28rem;
            display: flex;
            justify-content: center;
            color: rgb(0 0 0 / 30%);
            &.active-item {
                color: $text !important;
                font-size: 0.3rem !important;
            }
        }
    }
}
