@import "@/src/styles/common.scss";
.login-modal-drawer-wrapper {
    .login-modal-children {
        padding: 0 !important;

        .login-modal-container {
            // padding: 0.58rem 0 0;
            height: 100vh;
            &.forget-password-container {
                padding: 0;
            }
            // login
            .firmoo-login-container {
                flex: 1;
                overflow-y: scroll;
                padding: 0.98rem 0.56rem 0;
                .input-content + .input-content {
                    margin-top: 0.5rem;
                }
                .firmoo-register-error {
                    padding: 0.1rem 0.2rem;
                    background: $warning;
                    margin-top: 0;
                    width: fit-content;
                    @include use-font-small(#fff);
                }
                .radio-content {
                    margin-top: 0.4rem;
                }
                .login-content.login-btn {
                    margin: 0.4rem 0 0.1rem;
                    // height: 0.88rem;
                    // font-size: 0.32rem;
                }
                .forget-password-text {
                    font-size: 0.36rem;
                    height: 0.92rem;
                    margin-top: 0.2rem;
                    padding: 0;
                }
            }
            .firmoo-login-or {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                height: 0.28rem;
                padding: 0 0.56rem 0.4rem;
                .firmoo-login-or-line {
                    width: 45%;
                    height: 0.02rem;
                    background: rgba(228, 228, 228, 1);
                }
            }
            .facebook-login {
                padding-bottom: 0.64rem;
            }

            .root-tabs-content {
                border-bottom: 0.04rem solid $gray_4;
                margin: 0 auto;
                height: 1.24rem; // 0.92 + 0.32(底部高度撑开）
                padding-top: 0.32rem;
                // tab_&
                .tab-item {
                    font-size: 0.4rem;
                    position: relative;
                    padding: 0 0 0.32rem;
                    font-family: var(--font-montserrat-medium);
                    margin: 0;
                    color: #848a90;
                    &.active-tab-item {
                        color: $text;
                        font-family: var(--font-montserrat-bold);
                    }
                }
                .tab-item + .tab-item {
                    margin-left: 1.94rem;
                }
            }
            .root-tabs-container {
                height: 100vh;
                overflow: hidden;
                .tab-children-container {
                    max-height: calc(100vh - var(--tabs-container-height));
                }
            }
        }
    }

    .closeable-class {
        //     justify-content: flex-end;
        //     position: absolute;
        //     right: 0.15rem; // 边距要定死啊喂，到底是15px还是0.15rem
        padding: 0 !important;
        //     height: auto;
        //     top: 0.4rem;
        //     z-index: 1;
    }
    .login-or-facebook {
        padding: 0.4rem 0 0.64rem;
    }
}
