@import "@/src/styles/common.scss";
@import "./variable.module.scss";
.tab-box-container {
    height: 100%;
    .tab_wrapper {
        border-bottom: 0.02rem solid $gray_4;
        min-height: auto;
        height: 0.7rem;
        .indicator {
            background-color: $text;
            height: 0.04rem;
            border-radius: 0.06rem;
            &.hiddenIndicator {
                display: none;
            }
        }
        .root_tab {
            margin: 0;
            font-size: 0.28rem;
            cursor: pointer;
            flex-shrink: 0;
            text-transform: capitalize;
            position: relative;
            padding: 0;
            min-width: auto;
            min-height: auto;
            opacity: 1;
            color: $text;
            font-family: var(--font-montserrat);
            padding-bottom: 0.34rem;
            &.selected {
                font-family: var(--font-montserrat-bold);
            }
            &:first-of-type {
                margin-left: 0.34rem;
            }
        }
        .root_tab + .root_tab {
            margin-left: 0.4rem;
        }
    }
    .header-container ~ div {
        height: calc(100% - var(--tabs-container-height));
        overflow: auto;
    }
}
