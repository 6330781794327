@import "@/src/styles/common.scss";
.size-guide-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.2rem 0.4rem;

    .title {
        @include use-font-title();
        margin-top: 0.4rem;
        margin-bottom: 0.2rem;
    }

    .describle {
        font-size: 0.24rem;
        color: $text;
        margin-bottom: 0.4rem;
    }
}

.size-guide-img img {
    width: 100%;
    height: auto;
}
