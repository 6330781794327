// 主色
$main: #2ea7e0; // 常用经典色调
// 辅色
// 字体颜色
$text: #121a24;
$text_2: #464c51;
// 灰色
$gray_4: #e9eaec;
// 语义色
$favoriteColor: #e15e8a;
$disabled_color: #e9eaec;
$warning: #f8ac59;
:export {
    main: $main;
    text: $text;
    gray_4: $gray_4;
    favoriteColor: $favoriteColor;
    warning: $warning;
    text_2: $text_2;
    disabledColor: $disabled_color;
}
