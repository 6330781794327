@import "@/src/styles/common.scss";
.item-picker {
}
.picker-input-content-root {
    width: 60% !important;
}
.notched-outline.tel-register-outline {
    width: 60%;
    left: 38%;
    border: none;
}

.picker-input-root {
    border-top: none !important;
    border-left: none !important;
    border-bottom: none !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    font-size: 0.28rem !important;
    margin: 0;
    padding: 0 0 0 0.2rem !important;
    .hidden-input {
        display: none !important;
    }
    .picker-end-icon {
        right: 0.1rem;
    }
}
