@import "@/src/styles/common.scss";
.filter-size-auto-container {
    display: flex;
    flex-direction: column;
    justify-items: center;
    padding: 0.5rem 0.2rem;
    border-bottom: 0.02rem solid #dfdfdf;
    align-items: center;

    p {
        @include use-font-normal();
    }

    .line1 {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
            width: 1rem;
            height: auto;
        }
    }

    .line2 {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.38rem;

        .bar {
            width: 0.74rem;
            height: 0.01rem;
            background: #888;
            margin: 0 0.22rem;
        }
    }
}
