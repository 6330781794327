@import "@/src/styles/common.scss";
.firmoo-text-title {
    font-size: 0.48rem;
}
.firmoo-text-main {
    font-size: 0.36rem;
}
.firmoo-text-nav {
    font-size: 0.32rem;
}
.firmoo-text-discrible {
    font-size: 0.28rem;
}
.firmoo-text-sub {
    font-size: 0.26rem;
}
.firmoo-text-explain {
    font-size: 0.24rem;
}
.firmoo-text-color-main {
    color: $main;
}
.firmoo-text-color-title {
    color: $text;
}
.firmoo-text-color-sub {
    color: #666;
}
.firmoo-text-color-three {
    color: $text_3;
}
.firmoo-text-color-succeed {
    color: #21ac4e;
}
.firmoo-text-color-warn {
    color: $warning;
}
.firmoo-text-color-error {
    color: #e54542;
}
