// 主色
$main: #2ea7e0; // 常用经典色调
// 辅色
// 字体颜色
$text: #121a24;
$text_2: #464c51;
$text_3: #8e9399;
// 灰色
$gray_4: #e9eaec;
// 语义色
$favoriteColor: #e15e8a;

$disabled_color: #e9eaec;
$warning: #f8ac59;
$placeholderColor: #8e9399;
:export {
    main: $main;
    text: $text;
    gray_4: $gray_4;
    favoriteColor: $favoriteColor;
    warning: $warning;
    text_2: $text_2;
    text_3: $text_3;
    disabled_color: $disabled_color;
    placeholderColor: $placeholderColor;
}

// 字体标准：title
@mixin use-font-title($fontColor: $text) {
    font-size: 0.32rem;
    color: $fontColor;
    line-height: 1.5;
    font-family: var(--font-montserrat-medium);
}

// 字体标准：normal-description
@mixin use-font-normal($fontColor: $text, $important: null) {
    font-size: 0.28rem $important;
    color: $fontColor $important;
    line-height: 1.5 $important;
    font-family: var(--font-montserrat) $important;
}

@mixin use-font-small($fontColor: $text, $important: null) {
    font-size: 0.24rem $important;
    color: $fontColor $important;
    line-height: 1.5 $important;
    font-family: var(--font-montserrat) $important;
}
@mixin use-icon($important: null, $font: 0.4rem, $color: inherit) {
    @if $important == null {
        font-size: $font;
        line-height: 1;
        color: $color;
    } @else {
        font-size: $font $important;
        line-height: 1 $important;
        color: $color $important;
        width: auto;
        height: auto;
    }
}
$normalIconFont: 0.48rem; // 匹配28字号
$largeIconFont: 0.72rem; // 匹配32字号
$smaillIconFont: 0.32rem; // 匹配24字号
@mixin use-icon-normal($important: null, $color: inherit) {
    @include use-icon($important, $normalIconFont, $color);
}

@mixin use-icon-large($important: null, $color: inherit) {
    @include use-icon($important, $largeIconFont, $color);
}
@mixin use-icon-small($important: null, $color: inherit) {
    @include use-icon($important, $smaillIconFont, $color);
}
