@import "@/src/styles/common.scss";
.share-container {
    margin-top: 0.4rem;
    width: 100%;
    .button-container-wrapper {
        padding: 0 !important;
    }
    .button-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: var(--font-montserrat);
        width: 100%;
    }
    .icon-arrow-down {
        transition: all 0.3s;
        font-size: 0.44rem;
        margin-left: 0.08rem;
        font-family: var(--font-montserrat);
        &.margin-size {
            margin-right: 0.08rem;
        }
    }
    .firmoo-register-share {
        transform-origin: 0 0;
        transition: "all 0.3s";
        margin-top: 0.4rem;
        .label-item {
            margin-top: 0.1rem;
        }
    }
}
